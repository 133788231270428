<script>
import PageHeader from "@/components/page-header";
import { ApiClientSelectedPlatform } from "@/common/http-common"
import { BredcrumpManagerDecode } from "@/common/bredcrumb-common"
import { networkRequesErrorResponseLogic } from "@/common/http-common-response-error"
import { networkRequesListResponseLogic } from "@/common/http-common-response-list"
export default {
  components: {
    PageHeader
  },
  data() {
    return {
      title: this.$t('country.country'),
      items: [],
      pagination: {
        total: 0,
        totalPages: 0,
        page: 1,
        pageSize: 10,
        nextPages: [],
        backPages: []
      }

    };
  },
  watch: {
    $route (to,from){
      if (to.name == "country" && from.name == "platform/country")
      window.location.reload(true);
    }
  },
  methods: {
    getData: function (page, size) {
      const self = this;
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      var rs = JSON.parse(atob(this.$route.query.data))
      ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/countries?selected=True").then(function (response) {
        networkRequesListResponseLogic(self, response, page, size, "country.country")
      }).catch(
        function (error) {
          networkRequesErrorResponseLogic(self, error)
        }
      )
    },
    configureDataNextModule:function(id){
      if (this.$route.query.data){
        var rs = JSON.parse(atob(this.$route.query.data))
        rs.itemId = id
        return btoa(JSON.stringify(rs))
      }   
    },
    configureLinkNextModule:function(url){
        if (this.$route.path.includes("platform/detail/")){
          return "/platform/detail/"+url
        }else {
          return url
        }
    }
  },
  mounted() {
    if (this.$route.query.data) {
      var data = BredcrumpManagerDecode(this)
      if (data) {
        this.pagination.page = parseInt(data.page)
        this.pagination.pageSize = parseInt(data.size)
        this.getData(this.pagination.page, this.pagination.pageSize)
      } else {
        this.getData(this.pagination.page, this.pagination.pageSize)
      }
    } else {
      this.getData(this.pagination.page, this.pagination.pageSize)
    }
  }
};
</script>
<template>
  <div class="row">
    <div class="col-xl-12" style="padding: 0!important;">
            <PageHeader :title="title" />
        </div>
    <div class="col-xl-12">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ this.$t('country.country') }}</h4>
              <p class="card-title-desc">
                <router-link
                              :to="{ path: configureLinkNextModule('country/create'), query: { data: configureDataNextModule(null)} }">{{ $t("country.create") }}</router-link>
              </p>

              <div class="table-responsive">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th> {{ this.$t("country.list.country") }}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.id }}</td>
                      <td>{{ item.title }}</td>
                      <td><router-link
                              :to="{ path: configureLinkNextModule('country/update'), query: { data: configureDataNextModule(item.id)} }">{{ $t("country.limit") }}</router-link></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>